<template>
    <ion-page>
        <ion-content :fullscreen="true">
            <div class="login-container">
                <div class="login-sub-container" v-if="!isIpLocked">
                    <div><ion-icon src="assets/img/CC-Logo-2-2.svg" class="pattern-logo"></ion-icon></div>
                    <p class="center">
                        <span class="text-light">Welcome to</span><br />
                        <span class="text-medium">Pattern<br />Content Creator</span>
                    </p>
                    <div>
                        <hr />
                        <div class="input-group">
                            <label for="username">Username / Email</label>
                            <input 
                                autocomplete="off"
                                placeholder='Username / Email'
                                required
                                v-focus
                                v-model="loginValues.username"
                                type="text"
                                id="username"
                                name="username"
                                @keypress.enter="login"/>
                        </div>
                        <hr />
                        <div class="input-group">
                            <label for="password">Password</label>
                            <input
                                autocomplete="off"
                                placeholder="Password" 
                                required
                                v-model="loginValues.password"
                                type="password"
                                id="password"
                                name="password"
                                @keypress.enter="login"/>
                        </div>
                        <hr />
                        <br />
                        <button class="custom-button button-primary" @click="login">Log In</button>
                        <button class="custom-button custom-button-sm button-clear " @click="setOpen(true)">Reset Password</button>
                    </div>
                </div>
            </div>
            <ion-modal
                :is-open="passwordResetStartOpen"
                backdropDismiss="false"
                mode="md"
                css-class="password-reset-modal"
                @ionModalDidDismiss="setOpen(false)">
                <ion-content class="ion-padding">
                    <h2>Password Reset</h2>
                    <p>Enter your username or email to reset your password.</p>
                    <hr />
                    <div class="input-group">
                        <label for="email">Username / Email</label>
                        <input 
                            placeholder="Username / Email" 
                            required
                            v-model="passwordResetInfo"
                            type="email"
                            id="email"
                            name="email"
                            @keypress.enter="displayConfirmation"/>
                    </div>
                    <hr />
                    <div class="modal-buttons-container">
                        <span @click="setOpen(false)" class="plain-cancel">CANCEL</span>
                        <span @click="displayConfirmation" class="plain-ok">OK</span>
                    </div>
                </ion-content>
            </ion-modal>
        </ion-content>
    </ion-page>
</template>

<script lang="ts">
import { IonIcon, IonModal, IonContent, IonPage } from '@ionic/vue';
import { onBeforeMount, ref} from 'vue';
import { POST, PUT, GET } from '@/services/HTTP';
import { execute } from '@/mixins/LoadingMixin';
import { useRouter } from "vue-router";
import {presentNativeAlert, presentValidationAlert} from "@/services/Notify";
import {useStore} from "vuex";

export default ({
    name: 'Login',
    components: {
        IonIcon,
        IonModal,
        IonContent,
        IonPage
    },
    setup() {
        const store = useStore();
        const passwordResetStartOpen = ref(false);  
        const router = useRouter();
        const setOpen = (state: boolean) => passwordResetStartOpen.value = state;
        const loginValues: any = ref({
            username: '',
            password: ''
        } as any);
        const passwordResetInfo = ref("");
        const isIpLocked = ref(true);

        const showLockedMessage = ref(async () => {
            isIpLocked.value = await GET('/login');
            if (isIpLocked.value) {
                await presentNativeAlert({
                    backdropDismiss: false,
                    cssClass: 'custom-alert',
                    header: 'Too Many Attemps',
                    message: '<p>Your account is temporarily locked due to failed login attempts.<br /><br />Please try again later.</p>'
                });
            }
        });
        
        onBeforeMount(async () => {
            showLockedMessage.value();
        });
        
        const setDefaults = async (data: any) => {
            const user = await GET('/user/' + data.userId);
            await store.dispatch('auth/setLoggedInUser', user);
            await store.dispatch('app/setSelectedAppInstanceId', user.appInstancePreference);
        }
        
        const login = async () => {
            if (!loginValues.value.username) {
                presentValidationAlert('Please enter your username or email.');
                return;
            } else if (!loginValues.value.password) {
                presentValidationAlert('Please enter your password.');
                return;
            }
            await execute( async () => {
                const data = await POST('/login', loginValues.value);
                if (data) {
                    await store.dispatch('auth/setToken', data.token);
                    await setDefaults(data);
                    loginValues.value.username = '';
                    loginValues.value.password = '';
                    await router.push({ name: 'home' });
                } else {
                    showLockedMessage.value();
                }
            }, 'Error while logging in.');
        }
        
        const displayConfirmation = async () => {
            await execute(async () => {
                await PUT('/reset-password', { usernameOrEmail: passwordResetInfo.value });
                await presentNativeAlert({
                    cssClass: 'custom-alert',
                    header: 'Check Your Email',
                    message: 'If you have an account with us, an email has been sent with a link to reset your password.',
                    buttons: ['OK'],
                });
                setOpen(false);
            }, 'Error resetting password.');
        }

        return {
            passwordResetStartOpen,
            setOpen,
            displayConfirmation,
            loginValues,
            passwordResetInfo,
            login,
            isIpLocked,
            showLockedMessage
        }
    }
});
</script>

<style scoped>
#main-content > .ion-page > ion-content::part(background) {
    background-color: var(--color-step-950);
}

#main-content > .ion-page > ion-content::part(scroll) {
    --padding-start: 0;
    --padding-end: 0;
    margin-top: 0;
}

ion-icon {
    width: 160px;
    height: 160px;
    margin: 0 auto;
    display: block;
}

.password-reset-modal h2{
    font-size: 28px;
    line-height: 26px;
    color: var(--color-step-300);
}

.password-reset-modal p{
    font-weight: normal;
    font-size: 20px;
    line-height: 26px;
    color: var(--color-step-550);
}

.text-light {
    font-size: 21px;
    line-height: 48px;
}

.text-medium {
    font-size: 36px;
    line-height: 36px;
}

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.login-sub-container {
    background-color: var(--color-step-1000);
    width: 95%;
    box-shadow: 0px 3px 6px #00000040;
    padding: 30px 26px;
    border-radius: 10px;
}

.center {
    padding-bottom: 15px;
}

.modal-buttons-container {
    display: flex;
    padding-top: 30px;
}

.modal-buttons-container span {
    flex-grow: 1;
    font-size: 18px;
    padding: 0 26px;
    text-align: right;
}

.modal-buttons-container .plain-cancel {
    color: var(--color-step-750);
}

.modal-buttons-container .plain-ok {
    color: var(--ion-color-primary);
}

.button-primary {
    margin-top: 10px;
}

.custom-button {
    font-size: 22px;
}

.custom-button-sm{
    font-size: 16px!important;
    margin-top: 12px;
}

.pattern-logo {
   font-size:165px;
   color:var(--ion-color-dark);
}
</style>