
import { IonIcon, IonModal, IonContent, IonPage } from '@ionic/vue';
import { onBeforeMount, ref} from 'vue';
import { POST, PUT, GET } from '@/services/HTTP';
import { execute } from '@/mixins/LoadingMixin';
import { useRouter } from "vue-router";
import {presentNativeAlert, presentValidationAlert} from "@/services/Notify";
import {useStore} from "vuex";

export default ({
    name: 'Login',
    components: {
        IonIcon,
        IonModal,
        IonContent,
        IonPage
    },
    setup() {
        const store = useStore();
        const passwordResetStartOpen = ref(false);  
        const router = useRouter();
        const setOpen = (state: boolean) => passwordResetStartOpen.value = state;
        const loginValues: any = ref({
            username: '',
            password: ''
        } as any);
        const passwordResetInfo = ref("");
        const isIpLocked = ref(true);

        const showLockedMessage = ref(async () => {
            isIpLocked.value = await GET('/login');
            if (isIpLocked.value) {
                await presentNativeAlert({
                    backdropDismiss: false,
                    cssClass: 'custom-alert',
                    header: 'Too Many Attemps',
                    message: '<p>Your account is temporarily locked due to failed login attempts.<br /><br />Please try again later.</p>'
                });
            }
        });
        
        onBeforeMount(async () => {
            showLockedMessage.value();
        });
        
        const setDefaults = async (data: any) => {
            const user = await GET('/user/' + data.userId);
            await store.dispatch('auth/setLoggedInUser', user);
            await store.dispatch('app/setSelectedAppInstanceId', user.appInstancePreference);
        }
        
        const login = async () => {
            if (!loginValues.value.username) {
                presentValidationAlert('Please enter your username or email.');
                return;
            } else if (!loginValues.value.password) {
                presentValidationAlert('Please enter your password.');
                return;
            }
            await execute( async () => {
                const data = await POST('/login', loginValues.value);
                if (data) {
                    await store.dispatch('auth/setToken', data.token);
                    await setDefaults(data);
                    loginValues.value.username = '';
                    loginValues.value.password = '';
                    await router.push({ name: 'home' });
                } else {
                    showLockedMessage.value();
                }
            }, 'Error while logging in.');
        }
        
        const displayConfirmation = async () => {
            await execute(async () => {
                await PUT('/reset-password', { usernameOrEmail: passwordResetInfo.value });
                await presentNativeAlert({
                    cssClass: 'custom-alert',
                    header: 'Check Your Email',
                    message: 'If you have an account with us, an email has been sent with a link to reset your password.',
                    buttons: ['OK'],
                });
                setOpen(false);
            }, 'Error resetting password.');
        }

        return {
            passwordResetStartOpen,
            setOpen,
            displayConfirmation,
            loginValues,
            passwordResetInfo,
            login,
            isIpLocked,
            showLockedMessage
        }
    }
});
